import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Payer Object">
      <div className="headline">
        <h1 className="text-primary m-0">Payer Object</h1>
      </div>
      <div className="items-group">
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">type</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>
              Value fixed to<span className="reference">payer</span>, represents
              payer object
            </p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">id</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Unique identifier of payer</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">first_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Given name of payer</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">last_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Surname of payer</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">full_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Name of payer</p>
          </div>
        </div>
      </div>
    </DocumentLayout>
  )
}
